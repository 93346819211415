<template>
	<div id="account_manage">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="title" label="标题:" label-width="70px">
				<el-input placeholder="请输入" v-model="form.title"></el-input>
			</jy-query-item>
			<jy-query-item prop="releaseStatus" label="发布状态:">
				<el-select v-model="form.releaseStatus" placeholder="请选择">
					<el-option v-for="item in accountStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="发布日期:" prop="releaseDate">
				<el-date-picker v-model="form.releaseDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheudleSystemSettingsInformManagementList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('scheudleSystemSettingsInformManagementReset')"
					>重置</el-button
				>
				<!-- <el-row>
                </el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" v-on:click="addUdate()" v-if="btnexist('scheudleSystemSettingsInformManagementInsert')"
					>新增</el-button
				>
				<el-button type="primary" @click="handleBatchDelete" v-if="btnexist('scheudleSystemSettingsInformManagementBatchDelete')"
					>批量删除</el-button
				>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="dataList" @select="selectchange" @select-all="selectallchange">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column type="selection" width="40"></jy-table-column>
			<jy-table-column prop="title" label="标题" min-width="160"></jy-table-column>
			<jy-table-column prop="content" label="公告内容" min-width="200"></jy-table-column>
			<jy-table-column prop="orgNa" label="发布范围" min-width="200"></jy-table-column>
			<jy-table-column prop="creater" label="创建人" min-width="120"></jy-table-column>
			<jy-table-column prop="createT" label="创建日期" min-width="160"></jy-table-column>
			<jy-table-column label="发布状态" min-width="100">
				<template slot-scope="scope">{{ scope.row.rstatus | state }}</template>
			</jy-table-column>
			<jy-table-column prop="publisher" label="发布人" min-width="120"></jy-table-column>
			<jy-table-column prop="rDate" label="发布日期" min-width="160"></jy-table-column>
			<jy-operate v-bind:list="operateList" width="160"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<add-update-account ref="addUpdateAccount" @updateList="getList"></add-update-account>
	</div>
</template>
<script>
import addUpdateAccount from "@/components/pages/admin/systemSettings/informManagement/addUpdateInform";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			accountStatusList: [
				{
					label: "全部",
					value: ""
				},
				{
					label: "已发布",
					value: 1
				},
				{
					label: "未发布",
					value: 0
				}
			],
			form: {
				title: "",
				releaseStatus: "",
				releaseDate: ""
			},
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			operateList: [],
			// 批量删除
			batchdeleteList: [],

			btnMenuId: "scheudleSystemSettingsInformManagement"
		};
	},
	mixins: [btnMixins],
	components: {
		addUpdateAccount
	},
	created() {
		this.getList();
		this.setOperateList();
	},
	filters: {
		state(val) {
			if (val == 1) {
				return "已发布";
			} else {
				return "未发布";
			}
		}
	},
	methods: {
		setOperateList() {
			let l = [
				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.addUdate,
					isShow: row => {
						if (row.rstatus != 1 && this.btnexist("scheudleSystemSettingsInformManagementUpdate")) {
							return true;
						} else {
							return false;
						}
					}
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete,
					isShow: () => {
						return this.btnexist("scheudleSystemSettingsInformManagementDelete");
					}
				},
				{
					name: "发布",
					icon: "el-icon-view",
					fun: this.release,
					isShow: row => {
						if (row.rstatus != 1 && this.btnexist("scheudleSystemSettingsInformManagementRelease")) {
							return true;
						} else {
							return false;
						}
					}
				}
			];
			this.operateList = l;
		},
		getList() {
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/sysNotice/queryNotice";
			console.log(option);
			this.$http.post(url, option).then(({ detail }) => {
				// console.log(detail.list)
				this.dataList = detail.list;
				this.total = detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 批量选择
		selectchange(selection) {
			// console.log(selection)
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			// console.log(selection)
			this.batchdeleteList = selection;
		},
		// 批量删除
		handleBatchDelete() {
			if (!this.batchdeleteList.length) {
				this.$message({
					type: "warning",
					message: "请先选择删除项"
				});
				return false;
			}
			this.$confirm("是否删除选中公告?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = [];
					this.batchdeleteList.forEach(item => {
						option.push(item.id);
					});
					let url = "/sysNotice/batchDeleteNotice";
					let o = {
						ids: option.join(",")
					};
					this.$http.post(url, o).then(res => {
						this.$message({
							type: "success",
							message: res.msg
						});
						this.getList();
						this.batchdeleteList = [];
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该公告?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = { id: row.id };
					let url = "/sysNotice/deleteNotice";
					this.$http.post(url, option).then(res => {
						// console.log(res)
						this.$message({
							type: "success",
							message: res.msg
						});
						this.getList();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		addUdate(option) {
			if (option) {
				this.$refs.addUpdateAccount.init(option, "update");
			} else {
				this.$refs.addUpdateAccount.init();
			}
		},
		release(row) {
			this.$confirm("是否发布该公告?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = { id: row.id };
					let url = "/sysNotice/releaseNotice";
					this.$http.post(url, option).then(res => {
						// console.log(res)
						this.$message({
							type: "success",
							message: res.msg
						});
						this.getList();
					});
				})
				.catch(() => {});
		}
	}
};
</script>
<style scoped="accountManagement" lang="scss"></style>
